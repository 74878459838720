import { truncate } from 'lodash'
import get from 'lodash/get'
import { SerializableEvent } from './SerializableEvent'
import Moment from 'moment'

const serializeCopyCutPaste = (object) => {
  const clipboardData: any|DataTransfer = get(object.event, 'clipboardData', null)
  let clipboardDataContent = ('getData' in clipboardData && clipboardData.getData('text')) || null

  if (clipboardDataContent) {
    clipboardDataContent = truncate(clipboardDataContent, {length: 1024, omission: ' <...>'})
  }

  const data = {
    data: {
      innerText: get(object.event, 'target.innerText', null),
      className: get(object.event, 'target.className', null),
      nodeName: get(object.event, 'target.nodeName', null),
      clipboardData: clipboardDataContent,
      emitted_at: Moment().format()
    },
    type: object.type
  }
  return data
}
class DomEvent extends SerializableEvent {
  event: Event

  constructor(event) {
    super(event)
    this.event = event
  }

  serialize(): any {
    return {
      data: {...this.event},
      type: this.type
    }
  }
}

class CustomEvent extends SerializableEvent {
  serialize() {
    return {
      data: {
        ...this.event,
        emitted_at: this.emitted_at,
      },
      type: this.type
    }
  }
}

// CustomEvent
export class EnterFullscreenEvent extends CustomEvent {
  type = 'enter_fullscreen'
}

export class LeaveFullscreenEvent extends CustomEvent {
  type = 'leave_fullscreen'
}

export class ResizeScreenEvent extends CustomEvent {
  type = 'resize_screen'
}

export class EnterQuestionnaireLink extends CustomEvent {
  type = 'enter_questionnaire_link'
}

export class WebOfflineEvent extends CustomEvent {
  type = 'web_offline'
}

export class WebOnlineEvent extends CustomEvent {
  type = 'web_online'
}

export class ConnectionRateEvent extends CustomEvent {
  type = 'connection_rate'
}

export class EnterQuestionnairePauseEvent extends CustomEvent {
  type = 'enter_pause'
}

export class LeaveQuestionnairePauseEvent extends CustomEvent {
  type = 'leave_pause'
}

export class QuestionnaireJustifyEntranceEvent extends CustomEvent {
  type = 'justify_entrance'
}

export class StartProctoringRecordingEvent extends CustomEvent {
  type = 'start_proctoring_recording'
}

export class QuestionnaireFinishEvent extends CustomEvent {
  type = 'finish_questionnaire_event'
}

// MouseEvent
export class SelectedTextEvent extends DomEvent {
  type = 'selected_text'
  serialize() {
    const data = {
      data: {
        innerText: get(this.event, 'target.innerText', null),
        offsetX: get(this.event, 'offsetX', null),
        offsetY: get(this.event, 'offsetY', null),
        x: get(this.event, 'x', null),
        y: get(this.event, 'y', null),
        shiftKey: get(this.event, 'shiftKey', null),
        ctrlKey: get(this.event, 'ctrlKey', null),
        rangeOffset: get(this.event, 'rangeOffset', null),
        className: get(this.event, 'target.className', null),
        nodeName: get(this.event, 'target.nodeName', null),
        emitted_at: Moment().format(),
      },
      type: this.type
    }
    return data
  }
}

export class RightClickEvent extends DomEvent {
  type = 'right_click'
  serialize() {
    const data = {
      data: {
        innerText: get(this.event, 'target.innerText', null),
        offsetX: get(this.event, 'offsetX', null),
        offsetY: get(this.event, 'offsetY', null),
        x: get(this.event, 'x', null),
        y: get(this.event, 'y', null),
        shiftKey: get(this.event, 'shiftKey', null),
        ctrlKey: get(this.event, 'ctrlKey', null),
        rangeOffset: get(this.event, 'rangeOffset', null),
        className: get(this.event, 'target.className', null),
        selection: get(this.event, 'selection', null),
        nodeName: get(this.event, 'target.nodeName', null),
        emitted_at: Moment().format(),
      },
      type: this.type
    }
    return data
  }
}


// ClipboardEvent
export class CopyTextEvent extends DomEvent {
  type = 'copy_text'

  serialize() {
    return serializeCopyCutPaste(this)
  }
}

export class CutTextEvent extends DomEvent {
  type = 'cut_text'

  serialize() {
    return serializeCopyCutPaste(this)
  }
}

export class PasteTextEvent extends DomEvent {
  type = 'paste_text'

  serialize() {
    return serializeCopyCutPaste(this)
  }
}

export class PrintScreenEvent extends DomEvent {
  type = 'print_screen'
}
